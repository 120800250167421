import {
  ChangeEventHandler,
  DragEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  styled,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons/faCloudUpload';
import Paper from '@mui/material/Paper';
import Cropper, { Point, Area } from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { IMAGE_TYPES } from '@social-garden/utils/files.ts';
import { WHITE } from '@social-garden/utils/colors.ts';
import getCroppedImg from '../utils/cropImage.ts';

const VisuallyHiddenInput = styled('input')({
  position: 'absolute',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const DEFAULT_POINT: Point = { x: 0, y: 0 };

interface ImageCropFieldProps {
  id?: string;
  label?: string;
  name?: string;
  margin?: 'dense' | 'normal' | 'none';
  fullWidth?: boolean;
  required?: boolean;
  previewSrc?: string;
  error?: boolean;
  helperText?: ReactNode;
  width: number;
  height: number;
  showGrid?: boolean;
  cropShape?: 'rect' | 'round';
  onChange?(file: File): void;
}

export default function ImageCropField({
  id,
  label,
  name,
  required,
  margin,
  fullWidth,
  previewSrc,
  error,
  helperText,
  width,
  height,
  showGrid,
  cropShape,
  onChange,
}: ImageCropFieldProps) {
  const { t } = useTranslation(['common', 'manager']);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [croppedImagePreviewSrc, setCroppedImagePreviewSrc] = useState<
    string | undefined
  >(previewSrc);
  const [crop, setCrop] = useState<Point>(DEFAULT_POINT);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [zoom, setZoom] = useState<number>(1);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [highlightDropzone, setHighlightDropzone] = useState<boolean>(false);

  const aspect = useMemo(() => width / height, [width, height]);

  const selectedFileSrc = useMemo(
    () => (selectedFile ? URL.createObjectURL(selectedFile) : undefined),
    [selectedFile],
  );

  const resetFileInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.files = null;
    }
  }, []);

  const handleFile = useCallback((file: File) => {
    if (IMAGE_TYPES.find((type) => type === file.type)) {
      setSelectedFile(file);
      setDialogOpen(true);
    }
  }, []);

  const handleOnFileInputChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    async (event) => {
      if (event.target.files && event.target.files.length > 0) {
        handleFile(Array.from(event.target.files)[0]);
      }
    },
    [handleFile],
  );

  const handleOnDragOver = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => event.preventDefault(),
    [],
  );

  const handleOnDragEnter = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      setHighlightDropzone(true);
    },
    [],
  );

  const handleOnDragLeave = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      setHighlightDropzone(false);
    },
    [],
  );

  const handleOnDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (event) => {
      event.preventDefault();
      setHighlightDropzone(false);
      if (event.dataTransfer.files.length > 0) {
        handleFile(Array.from(event.dataTransfer.files)[0]);
      }
    },
    [handleFile],
  );

  const handleOnCropComplete = useCallback(
    (_: Area, newCroppedAreaPixels: Area) => {
      setCroppedAreaPixels(newCroppedAreaPixels);
    },
    [],
  );

  const handleClose = useCallback(() => {
    resetFileInput();
    setCrop(DEFAULT_POINT);
    setSelectedFile(undefined);
    setDialogOpen(false);
  }, [resetFileInput]);

  const handleOnSubmit = useCallback(async () => {
    if (selectedFileSrc === undefined || croppedAreaPixels === undefined) {
      return;
    }

    const croppedImageFile = await getCroppedImg(
      selectedFileSrc,
      croppedAreaPixels,
      {
        width,
        height,
      },
    );

    if (croppedImageFile === null) {
      return;
    }

    if (onChange) {
      onChange(croppedImageFile);
    }
    resetFileInput();
    setCroppedImagePreviewSrc(URL.createObjectURL(croppedImageFile));
    setCrop(DEFAULT_POINT);
    setDialogOpen(false);
  }, [
    croppedAreaPixels,
    height,
    onChange,
    resetFileInput,
    selectedFileSrc,
    width,
  ]);

  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      required={required}
      error={error}>
      <Paper
        sx={{
          display: 'flex',
          mb: 1,
          p: 5,
          height: 200,
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'border-color 250ms ease-in-out',
          backgroundColor: (theme) => theme.palette.background.default,
          borderColor: (theme) =>
            highlightDropzone ? theme.palette.primary.main : undefined,
        }}
        variant="outlined"
        onDragOver={handleOnDragOver}
        onDragEnter={handleOnDragEnter}
        onDragLeave={handleOnDragLeave}
        onDrop={handleOnDrop}>
        {croppedImagePreviewSrc ? (
          <img
            style={{
              pointerEvents: 'none',
              aspectRatio: aspect,
              height: 200,
              borderRadius: cropShape === 'round' ? '50%' : 0,
            }}
            src={croppedImagePreviewSrc}
            alt={t('common:preview')}
            draggable={false}
          />
        ) : (
          <Typography
            style={{
              pointerEvents: 'none',
            }}
            variant="h5"
            fontWeight={300}
            align="center"
            color="text.secondary">
            {t('manager:form.field.image.empty')}
          </Typography>
        )}
      </Paper>
      <Button
        id={id}
        fullWidth={fullWidth}
        component="label"
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faCloudUpload} />}>
        {label} {required ? '*' : null}
        <VisuallyHiddenInput
          ref={inputRef}
          hidden
          type="file"
          name={name}
          accept={IMAGE_TYPES.join(',')}
          onChange={handleOnFileInputChange}
        />
      </Button>
      <FormHelperText>{helperText}</FormHelperText>
      <Dialog open={dialogOpen} fullScreen onClose={handleClose}>
        <DialogContent>
          <Cropper
            style={{
              containerStyle: {
                background: WHITE,
              },
            }}
            image={selectedFileSrc}
            crop={crop}
            aspect={aspect}
            zoom={zoom}
            showGrid={showGrid}
            cropShape={cropShape}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleOnCropComplete}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button color="inherit" variant="contained" onClick={handleClose}>
            {t('common:cancel')}
          </Button>
          <Button
            disabled={
              selectedFileSrc === undefined || croppedAreaPixels === undefined
            }
            variant="contained"
            onClick={handleOnSubmit}>
            {t('common:done')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
}
