import { useSuspenseQuery } from '@apollo/client';
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Grid2,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { creatorHasCampaignDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useCallback, useMemo } from 'react';
import {
  formatEuroCents,
  getContrastColor,
  getLatestPreviewRevision,
  getUriWithHash,
} from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import {
  getContentTypeNameFromCampaignType,
  getMediaTypeFromContentType,
} from '@social-garden/utils/content.ts';
import {
  getChannelTypeColor,
  getChannelTypeName,
} from '@social-garden/utils/channel.ts';
import { faPhotoFilmMusic } from '@fortawesome/pro-thin-svg-icons/faPhotoFilmMusic';
import CreatorHasCampaignHeader from './CreatorHasCampaignHeader.tsx';
import ReplaceUrlWithLink from '../../components/ReplaceUrlWithLink.tsx';
import useCreatorHasCampaignStatus from '../../hooks/useCreatorHasCampaignStatus.ts';
import DeliveryAddress from '../../components/DeliveryAddress.tsx';
import ContentPreview from '../../components/ContentPreview.tsx';
import CreatorHasCampaignActionButton from './CreatorHasCampaignActionButton.tsx';
import CreatorHasCampaignContent from './CreatorHasCampaignContent.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import PriceSummary from '../../components/PriceSummary.tsx';

export default function CreatorHasCampaign() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common', 'manager']);

  const {
    data: { creatorHasCampaign },
  } = useSuspenseQuery(creatorHasCampaignDocument, {
    variables: {
      id: id ?? '',
    },
  });

  const { getCreatorHasCampaignStatusName, getCreatorHasCampaignStatusColor } =
    useCreatorHasCampaignStatus();

  const latestPreviewRevision = useMemo(
    () => getLatestPreviewRevision(creatorHasCampaign.previewRevisions),
    [creatorHasCampaign.previewRevisions],
  );

  const mediaType = useMemo(
    () =>
      getMediaTypeFromContentType(
        getContentTypeNameFromCampaignType(
          creatorHasCampaign.campaign.typeable,
        ),
      ),
    [creatorHasCampaign.campaign.typeable],
  );

  const handleOnNavigateToCampaign = useCallback(() => {
    navigate(`/campaign-details/${creatorHasCampaign.campaign.id}`);
  }, [navigate, creatorHasCampaign.campaign.id]);

  return (
    <Container>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Card variant="outlined">
            <CardMedia
              sx={{
                aspectRatio: 21 / 9,
                width: '100%',
                maxHeight: 250,
              }}
              image={getUriWithHash(creatorHasCampaign.campaign.headerImage)}
              title={creatorHasCampaign.campaign.name}
              draggable={false}
            />
            <CardContent>
              <Grid2 container spacing={2}>
                <Grid2 size={12}>
                  <CreatorHasCampaignHeader
                    creatorHasCampaign={creatorHasCampaign}
                    actionButton={
                      <CreatorHasCampaignActionButton
                        creatorHasCampaign={creatorHasCampaign}
                      />
                    }
                  />
                </Grid2>
                {creatorHasCampaign.creator.description ? (
                  <Grid2
                    size={{
                      xs: 12,
                      md: 10,
                      lg: 8,
                      xl: 6,
                    }}>
                    <Typography
                      style={{
                        whiteSpace: 'pre-line',
                      }}>
                      <ReplaceUrlWithLink>
                        {creatorHasCampaign.creator.description}
                      </ReplaceUrlWithLink>
                    </Typography>
                  </Grid2>
                ) : null}
                <Grid2 container size={12} spacing={1}>
                  <Grid2 size="auto">
                    <Chip
                      label={getCreatorHasCampaignStatusName(
                        creatorHasCampaign.status,
                      )}
                      variant="filled"
                      color={getCreatorHasCampaignStatusColor(
                        creatorHasCampaign.status,
                      )}
                      size="small"
                    />
                  </Grid2>
                  <Grid2 size="auto">
                    <Chip
                      sx={{
                        backgroundColor: getChannelTypeColor(
                          creatorHasCampaign.campaign.typeable.channelType,
                        ),
                        color: getContrastColor(
                          getChannelTypeColor(
                            creatorHasCampaign.campaign.typeable.channelType,
                          ),
                        ),
                      }}
                      label={getChannelTypeName(
                        creatorHasCampaign.campaign.typeable.channelType,
                      )}
                      variant="filled"
                      size="small"
                    />
                  </Grid2>
                  <Grid2 size="auto">
                    <Chip
                      sx={{
                        backgroundColor: getChannelTypeColor(
                          creatorHasCampaign.campaign.typeable.channelType,
                        ),
                        color: getContrastColor(
                          getChannelTypeColor(
                            creatorHasCampaign.campaign.typeable.channelType,
                          ),
                        ),
                      }}
                      label={getContentTypeNameFromCampaignType(
                        creatorHasCampaign.campaign.typeable,
                      )}
                      variant="filled"
                      size="small"
                    />
                  </Grid2>
                  <Grid2 size="auto">
                    <Tooltip
                      title={
                        <PriceSummary
                          price={creatorHasCampaign.price}
                          rewardValue={creatorHasCampaign.rewardValue}
                          minWidth={250}
                        />
                      }>
                      <Chip
                        label={`${t('common:price')}: ${formatEuroCents(creatorHasCampaign.price, resolvedLanguage)}`}
                        variant="filled"
                        size="small"
                      />
                    </Tooltip>
                  </Grid2>
                  <Grid2 size="auto">
                    <Chip
                      sx={{
                        textDecoration: 'underline',
                      }}
                      label={`${t('manager:creatorHasCampaign.campaign.name.label')}: ${creatorHasCampaign.campaign.name}`}
                      variant="filled"
                      size="small"
                      clickable
                      onClick={handleOnNavigateToCampaign}
                    />
                  </Grid2>
                </Grid2>
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
        {creatorHasCampaign.answers.length > 0 ? (
          <Grid2
            size={{
              xs: 12,
              md: 6,
              lg: 4,
            }}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="overline" component="div">
                  {t('manager:creatorHasCampaign.questionsAndAnswers.header')}
                </Typography>
                <Stack spacing={2}>
                  {creatorHasCampaign.answers.map((answer, index) => (
                    <Stack key={answer.id}>
                      <Typography variant="subtitle2">
                        {`${index + 1}. ${answer.question.question}`}
                      </Typography>
                      <Typography>{answer.answer}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Grid2>
        ) : null}
        {creatorHasCampaign.campaignGiftCodes.length > 0 ? (
          <Grid2
            size={{
              xs: 12,
              md: 6,
              lg: 4,
            }}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="overline" component="div">
                  {t('manager:creatorHasCampaign.giftCode.header')}
                </Typography>
                <Stack spacing={2}>
                  {creatorHasCampaign.campaignGiftCodes.map((giftCode) => (
                    <Stack key={giftCode.id}>
                      <Typography variant="subtitle2">
                        {giftCode.gift.name}
                      </Typography>
                      <Typography>{giftCode.code}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Grid2>
        ) : null}
        {creatorHasCampaign.deliveryAddress ? (
          <Grid2
            size={{
              xs: 12,
              md: 6,
              lg: 4,
            }}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="overline" component="div">
                  {t('manager:creatorHasCampaign.deliveryAddress.header')}
                </Typography>
                <DeliveryAddress
                  deliveryAddress={creatorHasCampaign.deliveryAddress}
                />
              </CardContent>
            </Card>
          </Grid2>
        ) : null}
        <Grid2 container size={12} spacing={0}>
          <Grid2 size={12}>
            <Typography variant="overline" fontSize={18}>
              {t('manager:creatorHasCampaign.contentPreview.header')}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            {latestPreviewRevision ? (
              <Card variant="outlined">
                <ContentPreview
                  id={latestPreviewRevision.id}
                  mediaType={mediaType}
                />
                <Divider />
                <CardContent>
                  <Typography
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                    variant="body2">
                    <ReplaceUrlWithLink>
                      {latestPreviewRevision.caption}
                    </ReplaceUrlWithLink>
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <Card variant="outlined">
                <CardContent>
                  <EmptyState
                    icon={faPhotoFilmMusic}
                    text={t('manager:creatorHasCampaign.contentPreview.empty')}
                  />
                </CardContent>
              </Card>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={0}>
          <Grid2 size={12}>
            <Typography variant="overline" fontSize={18}>
              {t('manager:creatorHasCampaign.content.header')}
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            {creatorHasCampaign.contentUrl ? (
              <CreatorHasCampaignContent
                contentUrl={creatorHasCampaign.contentUrl}
                channelType={creatorHasCampaign.campaign.typeable.channelType}
              />
            ) : (
              <Card variant="outlined">
                <CardContent>
                  <EmptyState
                    icon={faPhotoFilmMusic}
                    text={t('manager:creatorHasCampaign.content.empty')}
                  />
                </CardContent>
              </Card>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
    </Container>
  );
}
