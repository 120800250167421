import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  loginManagerDocument,
  resendManagerVerificationEmailDocument,
} from '@social-garden/api/documents/manager.ts';
import { useNavigate, useSearchParams } from 'react-router';
import { setToken } from '../utils/authToken.ts';

export default function useLoginManager() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [login, { client, loading: loginLoading, error }] =
    useMutation(loginManagerDocument);
  const [resendManagerVerificationEmail, { loading: resendLoading }] =
    useMutation(resendManagerVerificationEmailDocument);

  const handleLogin = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        const result = await login({
          variables: {
            input: {
              email,
              password,
              deviceName: navigator.userAgent,
            },
          },
        });
        if (result.data?.loginManager) {
          setToken(result.data.loginManager);
          await client.resetStore();
          let toRoute = searchParams.get('from') || '/';
          if (toRoute === '/logout') {
            toRoute = '/';
          }
          navigate(toRoute);
        }
      } catch (e: any) {
        if (e.message === 'Email not verified!') {
          await resendManagerVerificationEmail({
            variables: {
              input: {
                email,
              },
            },
          });
          navigate(`/verify-email?email=${encodeURIComponent(email)}`, {
            state: {
              password,
            },
          });
        }
      }
    },
    [client, login, navigate, resendManagerVerificationEmail, searchParams],
  );

  return [
    handleLogin,
    { loading: loginLoading || resendLoading, error },
  ] as const;
}
