import {
  Card,
  CardContent,
  CardProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

interface ReportStatCardProps extends CardProps {
  label: string;
  value: string | number;
  description?: string;
}

export default function ReportStatisticCard({
  label,
  value,
  description,
  ...rest
}: ReportStatCardProps) {
  return (
    <Card {...rest}>
      <CardContent>
        <Tooltip title={description}>
          <Stack>
            <Typography variant="overline" component="div">
              {label}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Stack>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
