import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { Button, Container, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  brandsDocument,
  createBrandDocument,
} from '@social-garden/api/documents/brand.ts';
import {
  CreateBrandFieldValues,
  CreateBrandSchema,
} from '../../constants/ValidationSchema.ts';
import ImageCropField from '../../components/ImageCropField.tsx';
import { FormFieldProps } from '../../utils/types.ts';
import useBrandUsage from '../../hooks/useBrandUsage.ts';
import PageTitle from '../../components/PageTitle.tsx';
import BrandNameField from '../../components/BrandNameField.tsx';

export default function CreateBrand() {
  const { t } = useTranslation(['common', 'manager']);
  const navigate = useNavigate();
  const { exceeded } = useBrandUsage();
  const [createBrand, { loading }] = useMutation(createBrandDocument, {
    refetchQueries: [brandsDocument],
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateBrandFieldValues>({
    mode: 'all',
    resolver: zodResolver(CreateBrandSchema),
  });

  const renderLogoField = useCallback(
    ({
      field: { name, onChange },
      fieldState: { error, invalid },
    }: FormFieldProps<CreateBrandFieldValues, 'logo'>) => (
      <ImageCropField
        fullWidth
        label={t('common:brand.logo')}
        width={500}
        height={500}
        error={invalid}
        helperText={error?.message}
        name={name}
        cropShape="round"
        onChange={onChange}
      />
    ),
    [t],
  );

  const handleOnValid = useCallback(
    async ({ logo, name }: CreateBrandFieldValues) => {
      const { data } = await createBrand({
        variables: {
          input: {
            logo,
            name,
          },
        },
      });
      if (data?.createBrand) {
        navigate('/brands');
      }
    },
    [createBrand, navigate],
  );

  if (exceeded) {
    navigate('/brands');
  }

  return (
    <Container
      component="form"
      maxWidth="md"
      noValidate
      onSubmit={handleSubmit(handleOnValid)}>
      <Stack spacing={4}>
        <PageTitle>{t('manager:brand.create.title')}</PageTitle>
        <Stack spacing={2}>
          <Controller<CreateBrandFieldValues, 'logo'>
            control={control}
            render={renderLogoField}
            name="logo"
          />
          <Controller<CreateBrandFieldValues, 'name'>
            control={control}
            render={BrandNameField}
            name="name"
          />
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid || loading || exceeded}>
            {t('manager:brand.create.submitButton')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
