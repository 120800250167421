import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { logoutManagerDocument } from '@social-garden/api/documents/manager.ts';
import { removeToken } from '../utils/authToken.ts';
import { persistor } from '../utils/apolloInMemoryCache.ts';

export default function useLogoutManager() {
  const navigate = useNavigate();
  const [logout, { error, loading, client }] = useMutation(
    logoutManagerDocument,
  );

  const handleLogout = useCallback(async () => {
    try {
      await logout();
    } finally {
      removeToken();
      await persistor.purge();
      await client.clearStore();
      navigate('/login');
    }
  }, [client, logout, navigate]);

  return [
    handleLogout,
    {
      error,
      loading,
    },
  ] as const;
}
