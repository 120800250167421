import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router';
import RootRoutes from './RootRoutes.tsx';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

const router = sentryCreateBrowserRouter([RootRoutes]);

export default router;
