import { useEffect } from 'react';
import { Card, Container, Stack } from '@mui/material';
import { useParams } from 'react-router';
import MessagesFooter from './MessagesFooter.tsx';
import MessagesList from './MessagesList.tsx';
import MessagesHeader from './MessagesHeader.tsx';

export default function Messages() {
  const { id } = useParams();

  useEffect(() => {
    window.document.body.style.overflowY = 'hidden';
    return () => {
      window.document.body.style.overflowY = '';
    };
  }, []);

  if (!id) {
    throw new Error('Creator has campaign ID must be provided!');
  }

  return (
    <Container
      sx={{
        height: `calc(100vh - 50px)`,
        display: 'flex',
        flexDirection: 'column',
        py: {
          xs: 2,
          sm: 3,
        },
        my: {
          xs: -2,
          sm: -3,
        },
      }}>
      <Card
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
        variant="outlined">
        <Stack borderBottom={1} borderColor="divider" p={1}>
          <MessagesHeader creatorHasCampaignId={id} />
        </Stack>
        <MessagesList creatorHasCampaignId={id} />
        <Stack borderTop={1} borderColor="divider" p={1}>
          <MessagesFooter creatorHasCampaignId={id} />
        </Stack>
      </Card>
    </Container>
  );
}
