export const IMAGE_SIZE_LIMIT_IN_BYTES = 10485760;

export const PREVIEW_FILE_SIZE_LIMIT_IN_BYTES = 524288000;

export const CONTENT_FILE_SIZE_LIMIT_IN_BYTES = 524288000;

export const IMAGE_TYPES = [
  'image/webp',
  'image/png',
  'image/jpg',
  'image/jpeg',
] as const;
