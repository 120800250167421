import { useTranslation } from 'react-i18next';
import { Link, Grid2 } from '@mui/material';
import { Link as RouterLink } from 'react-router';
import { DataGrid } from '@mui/x-data-grid';
import {
  formatEuroCents,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import CampaignReportToolbar from './CampaignReportToolbar.tsx';
import ReportStatisticCard from '../../components/ReportStatisticCard.tsx';
import CampaignReportDataStatus from './CampaignReportDataStatus.tsx';

interface TiktokVideoCampaignReportData {
  id: string;
  completedCount: number;
  followerCount: number;
  views: number;
  likes: number;
  comments: number;
  shares: number;
  engagementRate?: number | null;
  cpv?: number | null;
  price: number;
  creatorHasCampaignReports: {
    id: string;
    creatorHasCampaign: {
      id: string;
      status: CreatorHasCampaignStatus;
      creator: {
        username: string;
      };
    };
    followerCount: number;
    views: number;
    likes: number;
    comments: number;
    shares: number;
    engagementRate?: number | null;
    cpv?: number | null;
    price: number;
  }[];
}

interface TiktokVideoCampaignReportProps {
  data: TiktokVideoCampaignReportData;
}

export default function TiktokVideoCampaignReport({
  data,
}: TiktokVideoCampaignReportProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Grid2 container spacing={4}>
      <Grid2 container size={12} spacing={2}>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.completed')}
            value={data.completedCount.toLocaleString(resolvedLanguage)}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.followers')}
            value={data.followerCount.toLocaleString(resolvedLanguage)}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.views')}
            description={t('common:report.viewsDescription')}
            value={data.views.toLocaleString(resolvedLanguage)}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.likes')}
            value={data.likes.toLocaleString(resolvedLanguage)}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.comments')}
            value={data.comments.toLocaleString(resolvedLanguage)}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.shares')}
            value={data.shares.toLocaleString(resolvedLanguage)}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.engagementRate')}
            description={t('common:report.engagementRateDescription')}
            value={
              data.engagementRate
                ? formatPercentage(data.engagementRate, resolvedLanguage)
                : 'n/a'
            }
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.cpv')}
            description={t('common:report.cpvDescription')}
            value={
              data.cpv ? formatEuroCents(data.cpv, resolvedLanguage) : 'n/a'
            }
          />
        </Grid2>
        <Grid2
          size={{
            xs: 6,
            sm: 6,
            md: 3,
            lg: 2,
          }}>
          <ReportStatisticCard
            variant="outlined"
            label={t('common:report.price')}
            value={formatEuroCents(data.price, resolvedLanguage)}
          />
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <DataGrid
          rows={data.creatorHasCampaignReports}
          columns={[
            {
              flex: 1,
              minWidth: 150,
              field: 'creatorUsername',
              headerName: t('common:username'),
              valueGetter: (_, row) => row.creatorHasCampaign.creator.username,
              renderCell: ({ row }) => (
                <Link
                  component={RouterLink}
                  to={`/creator-has-campaign/${row.creatorHasCampaign.id}`}>
                  {row.creatorHasCampaign.creator.username}
                </Link>
              ),
            },
            {
              width: 120,
              field: 'followerCount',
              headerName: t('common:report.followers'),
              valueGetter: (_, row) =>
                row.followerCount.toLocaleString(resolvedLanguage),
            },
            {
              width: 120,
              field: 'views',
              headerName: t('common:report.views'),
              description: t('common:report.viewsDescription'),
              valueGetter: (_, row) =>
                row.views.toLocaleString(resolvedLanguage),
            },
            {
              width: 120,
              field: 'likes',
              headerName: t('common:report.likes'),
              valueGetter: (_, row) =>
                row.likes.toLocaleString(resolvedLanguage),
            },
            {
              width: 120,
              field: 'comments',
              headerName: t('common:report.comments'),
              valueGetter: (_, row) =>
                row.comments.toLocaleString(resolvedLanguage),
            },
            {
              width: 120,
              field: 'shares',
              headerName: t('common:report.shares'),
              valueGetter: (_, row) =>
                row.shares.toLocaleString(resolvedLanguage),
            },
            {
              width: 160,
              field: 'engagementRate',
              headerName: t('common:report.engagementRate'),
              description: t('common:report.engagementRateDescription'),
              valueGetter: (_, row) =>
                row.engagementRate
                  ? formatPercentage(row.engagementRate, resolvedLanguage)
                  : 'n/a',
            },
            {
              width: 100,
              field: 'cpv',
              headerName: t('common:report.cpv'),
              description: t('common:report.cpvDescription'),
              valueGetter: (_, row) =>
                row.cpv ? formatEuroCents(row.cpv, resolvedLanguage) : 'n/a',
            },
            {
              width: 120,
              field: 'price',
              headerName: t('common:report.price'),
              valueGetter: (_, row) =>
                formatEuroCents(row.price, resolvedLanguage),
            },
            {
              width: 50,
              field: 'status',
              align: 'center',
              disableExport: true,
              renderHeader: () => null,
              valueGetter: (_, row) => row.creatorHasCampaign.status,
              renderCell: ({ row }) => (
                <CampaignReportDataStatus
                  creatorHasCampaignStatus={row.creatorHasCampaign.status}
                />
              ),
            },
          ]}
          disableColumnSorting={false}
          slots={{
            toolbar: CampaignReportToolbar,
            pagination: null,
          }}
        />
      </Grid2>
    </Grid2>
  );
}
