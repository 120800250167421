import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import {
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  resendManagerVerificationEmailDocument,
  verifyManagerEmailDocument,
} from '@social-garden/api/documents/manager.ts';
import {
  VerifyEmailForm,
  VerifyEmailSchema,
} from '../../constants/ValidationSchema.ts';
import CodeField from '../../components/CodeField.tsx';
import ReadOnlyEmailField from '../../components/ReadOnlyEmailField.tsx';
import useLoginManager from '../../hooks/useLoginManager.ts';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state }: Location<{ password: string } | undefined | null> =
    useLocation();
  const { t } = useTranslation(['manager']);
  const [verifyManagerEmail, { loading: verifyManagerEmailLoading }] =
    useMutation(verifyManagerEmailDocument);
  const [
    resendManagerVerificationEmail,
    { loading: resendManagerVerificationEmailLoading },
  ] = useMutation(resendManagerVerificationEmailDocument);
  const [loginManager, { loading: loginManagerLoading }] = useLoginManager();
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<VerifyEmailForm>({
    resolver: zodResolver(VerifyEmailSchema),
    mode: 'all',
    defaultValues: {
      email: searchParams.get('email') || undefined,
      code: searchParams.get('code') || undefined,
    },
  });

  const handleOnResendCode = useCallback(async () => {
    const email = searchParams.get('email');
    if (email === null) {
      return;
    }
    await resendManagerVerificationEmail({
      variables: {
        input: {
          email,
        },
      },
    });
  }, [searchParams, resendManagerVerificationEmail]);

  const handleOnValid = useCallback(
    async ({ email, code }: VerifyEmailForm) => {
      const result = await verifyManagerEmail({
        variables: {
          input: {
            email,
            code,
          },
        },
      });
      if (!result.data) {
        return;
      }
      if (state?.password) {
        await loginManager({
          email,
          password: state.password,
        });
      } else {
        navigate('/login');
      }
    },
    [verifyManagerEmail, state?.password, loginManager, navigate],
  );

  return (
    <Stack spacing={4}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h5" align="center">
          {t('verifyEmail.header')}
        </Typography>
      </Stack>
      <Stack
        spacing={2}
        component="form"
        noValidate
        onSubmit={handleSubmit(handleOnValid)}>
        <Controller<VerifyEmailForm, 'email'>
          name="email"
          control={control}
          render={ReadOnlyEmailField}
        />
        <Controller<VerifyEmailForm, 'code'>
          name="code"
          control={control}
          render={CodeField}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={
            !isValid || verifyManagerEmailLoading || loginManagerLoading
          }>
          {t('verifyEmail.submitButton')}
        </Button>
        <Button
          variant="outlined"
          disabled={resendManagerVerificationEmailLoading}
          onClick={handleOnResendCode}>
          {t('verifyEmail.resendCodeButton')}
        </Button>
      </Stack>
    </Stack>
  );
}
