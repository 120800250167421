import { useLocation, useSearchParams } from 'react-router';
import { useMemo } from 'react';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';
import useStripeCustomerPortalUrl from '../../hooks/useStripeCustomerPortalUrl.ts';

export default function CustomerPortal() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const returnUrl = useMemo(() => {
    const returnUrlParam = searchParams.get('return-url');
    if (
      returnUrlParam === null ||
      returnUrlParam.includes(window.location.origin + pathname)
    ) {
      return window.location.origin;
    }
    return returnUrlParam;
  }, [searchParams, pathname]);

  const { data, error } = useStripeCustomerPortalUrl(returnUrl);

  if (error) {
    window.location.replace(returnUrl);
  } else {
    window.location.replace(data.stripeCustomerPortalUrl);
  }
  return <SuspenseFallback />;
}
