import { Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { requestVerificationOfNewManagerEmailDocument } from '@social-garden/api/documents/manager.ts';
import {
  RequestVerificationOfNewManagerEmailFieldValues,
  RequestVerificationOfNewManagerEmailSchema,
} from '../../constants/ValidationSchema.ts';
import EmailField from '../../components/EmailField.tsx';
import Section from '../../components/Section.tsx';

interface RequestNewEmailProps {
  currentEmail: string;
}

export default function RequestNewEmail({
  currentEmail,
}: RequestNewEmailProps) {
  const { t } = useTranslation(['manager']);
  const navigate = useNavigate();
  const [requestVerificationOfNewManagerEmail, { loading }] = useMutation(
    requestVerificationOfNewManagerEmailDocument,
  );

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useForm<RequestVerificationOfNewManagerEmailFieldValues>({
    resolver: zodResolver(RequestVerificationOfNewManagerEmailSchema),
    mode: 'all',
    defaultValues: {
      email: currentEmail,
    },
  });

  const handleOnValid = useCallback(
    async ({ email }: RequestVerificationOfNewManagerEmailFieldValues) => {
      const result = await requestVerificationOfNewManagerEmail({
        variables: {
          input: {
            email,
          },
        },
      });
      if (result.data?.requestVerificationOfNewManagerEmail) {
        navigate(`/verify-updated-email?email=${encodeURIComponent(email)}`);
      }
    },
    [requestVerificationOfNewManagerEmail, navigate],
  );

  return (
    <form noValidate onSubmit={handleSubmit(handleOnValid)}>
      <Section header={t('manager:requestNewEmail.header')}>
        <Controller<RequestVerificationOfNewManagerEmailFieldValues, 'email'>
          name="email"
          control={control}
          render={EmailField}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || !isDirty || loading}>
          {t('manager:requestNewEmail.submitButton')}
        </Button>
      </Section>
    </form>
  );
}
