import {
  Button,
  Card,
  CardContent,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import { ChannelType } from '@social-garden/utils/types.ts';
import { useMemo } from 'react';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import OEmbed from '../../components/OEmbed.tsx';
import useChannelTypeOEmbed from '../../hooks/useChannelTypeOEmbed.ts';

const MAX_WIDTH = 800;

interface CreatorHasCampaignContentProps {
  contentUrl: string;
  channelType: ChannelType;
}

export default function CreatorHasCampaignContent({
  contentUrl,
  channelType,
}: CreatorHasCampaignContentProps) {
  const { t } = useTranslation(['manager']);

  const { data, loading } = useChannelTypeOEmbed(contentUrl, channelType, {
    maxWidth: MAX_WIDTH,
  });

  const defaultHeight = useMemo(() => {
    switch (channelType) {
      case ChannelType.INSTAGRAM:
        return 1130;
      case ChannelType.TIKTOK:
        return 1024;
      case ChannelType.YOUTUBE:
        return 600;
      default:
        assertUnreachable(channelType);
    }
  }, [channelType]);

  if (loading) {
    return <Skeleton variant="rounded" width="100%" height={defaultHeight} />;
  }

  if (data === undefined || (data.type !== 'video' && data.type !== 'rich')) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Stack
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            spacing={2}
            height={400}>
            <Typography variant="h5" align="center">
              {t('manager:contentEmbed.unavailable.header')}
            </Typography>
            <Button
              variant="text"
              href={contentUrl}
              target="_blank"
              startIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}>
              {t('manager:contentEmbed.unavailable.openContentButton')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Stack spacing={0.5}>
      <OEmbed data={data} defaultHeight={defaultHeight} maxWidth={MAX_WIDTH} />
      <Stack direction="row">
        <Link href={contentUrl} target="_blank" variant="caption">
          <FontAwesomeIcon
            style={{ marginRight: 4 }}
            icon={faArrowUpRightFromSquare}
          />
          {contentUrl}
        </Link>
      </Stack>
    </Stack>
  );
}
