import { Button, Stack, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { forgotManagerPasswordDocument } from '@social-garden/api/documents/manager.ts';
import {
  ForgotPasswordFieldValues,
  ForgotPasswordSchema,
} from '../../constants/ValidationSchema.ts';
import EmailField from '../../components/EmailField.tsx';

export default function ForgotPassword() {
  const { t } = useTranslation(['manager']);
  const navigate = useNavigate();
  const [forgotManagerPassword, { loading }] = useMutation(
    forgotManagerPasswordDocument,
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<ForgotPasswordFieldValues>({
    resolver: zodResolver(ForgotPasswordSchema),
    mode: 'all',
  });

  const handleOnValid = useCallback(
    async ({ email }: ForgotPasswordFieldValues) => {
      const result = await forgotManagerPassword({
        variables: {
          input: {
            email,
          },
        },
      });

      if (result.data?.forgotManagerPassword) {
        navigate(`/recover-password?email=${email}`);
      }
    },
    [forgotManagerPassword, navigate],
  );

  return (
    <Stack spacing={4}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h5" align="center">
          {t('forgotPassword.header')}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {t('forgotPassword.description')}
        </Typography>
      </Stack>
      <Stack
        component="form"
        spacing={2}
        noValidate
        onSubmit={handleSubmit(handleOnValid)}>
        <Controller<ForgotPasswordFieldValues, 'email'>
          name="email"
          control={control}
          render={EmailField}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || loading}>
          {t('forgotPassword.submitButton')}
        </Button>
      </Stack>
    </Stack>
  );
}
