import { useTranslation } from 'react-i18next';
import { Grid2, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { ORANGE } from '@social-garden/utils/colors.ts';
import { ReactNode } from 'react';
import ProfilePicture from '../../components/ProfilePicture.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import { InstagramChannelInsightsData } from '../../components/InstagramChannelInsights.tsx';
import ChannelStatistics, {
  InstagramChannelStatistics,
  TiktokChannelStatistics,
  YoutubeChannelStatistics,
} from '../../components/ChannelStatistics.tsx';
import ChannelMissing from '../../components/ChannelMissing.tsx';

interface CreatorHasCampaignHeaderProps {
  creatorHasCampaign: {
    creator: {
      username: string;
      isUnreliable: boolean;
      profilePicture?: {
        uri: string;
        hash?: string | null;
      } | null;
    };
    channel?: {
      channelable:
        | (InstagramChannelStatistics & InstagramChannelInsightsData)
        | TiktokChannelStatistics
        | YoutubeChannelStatistics;
    } | null;
  };
  actionButton: ReactNode;
}

export default function CreatorHasCampaignHeader({
  creatorHasCampaign,
  actionButton,
}: CreatorHasCampaignHeaderProps) {
  const { t } = useTranslation(['manager']);

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{
          xs: 12,
          sm: 'auto',
          md: 'auto',
        }}
        display="flex"
        justifyContent={{
          xs: 'center',
          sm: 'flex-start',
        }}>
        <ProfilePicture
          username={creatorHasCampaign.creator.username}
          src={creatorHasCampaign.creator.profilePicture}
          size={150}
        />
      </Grid2>
      <Grid2
        container
        size={{
          xs: 12,
          sm: 'grow',
          md: 'grow',
        }}
        spacing={0.5}
        display="flex"
        flexDirection="column">
        <Grid2
          container
          size={12}
          spacing={1}
          display="flex"
          justifyContent={{
            xs: 'center',
            sm: 'flex-start',
          }}>
          <Grid2 size="auto">
            <PageTitle>{creatorHasCampaign.creator.username}</PageTitle>
          </Grid2>
          {creatorHasCampaign.creator.isUnreliable ? (
            <Grid2 size="auto">
              <Tooltip title={t('manager:creator.isUnreliable')}>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  size="2xl"
                  color={ORANGE['500']}
                />
              </Tooltip>
            </Grid2>
          ) : null}
        </Grid2>
        {creatorHasCampaign.channel ? (
          <Grid2 size={12}>
            <ChannelStatistics data={creatorHasCampaign.channel.channelable} />
          </Grid2>
        ) : (
          <Grid2
            size={12}
            display="flex"
            justifyContent={{
              xs: 'center',
              sm: 'flex-start',
            }}>
            <ChannelMissing />
          </Grid2>
        )}
      </Grid2>
      <Grid2
        size={{
          xs: 12,
          sm: 12,
          md: 12,
          lg: 'auto',
        }}>
        {actionButton}
      </Grid2>
    </Grid2>
  );
}
