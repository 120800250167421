import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faRepeat } from '@fortawesome/pro-regular-svg-icons/faRepeat';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons/faChartMixed';
import { faMessage } from '@fortawesome/pro-regular-svg-icons/faMessage';
import { faChartPie } from '@fortawesome/pro-regular-svg-icons/faChartPie';
import { faFileArrowDown } from '@fortawesome/pro-regular-svg-icons/faFileArrowDown';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  acceptPreviewDocument,
  declineCampaignApplicationDocument,
} from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useNavigate } from 'react-router';
import { CampaignGiftType } from '@social-garden/utils/types.ts';
import ActionButton from '../../components/ActionButton.tsx';
import useDownloadContentFile from '../../hooks/useDownloadContentFile.ts';
import InstagramChannelInsightsDialog from '../../components/InstagramChannelInsightsDialog.tsx';
import AcceptCampaignApplicationDialog from './AcceptCampaignApplicationDialog.tsx';
import RetryPreviewDialog from './RetryPreviewDialog.tsx';
import {
  InstagramChannelStatistics,
  TiktokChannelStatistics,
  YoutubeChannelStatistics,
} from '../../components/ChannelStatistics.tsx';
import { InstagramChannelInsightsData } from '../../components/InstagramChannelInsights.tsx';

interface CreatorHasCampaignActionButtonProps {
  creatorHasCampaign: {
    id: string;
    status: CreatorHasCampaignStatus;
    rewardValue?: number | null;
    price: number;
    channel?: {
      channelable:
        | (InstagramChannelStatistics & InstagramChannelInsightsData)
        | TiktokChannelStatistics
        | YoutubeChannelStatistics;
    } | null;
    creator: {
      username: string;
    };
    campaign: {
      id: string;
      includeContentFileOnSubmission: boolean;
      gifts: {
        id: string;
        name: string;
        type: CampaignGiftType;
      }[];
    };
  };
}

export default function CreatorHasCampaignActionButton({
  creatorHasCampaign,
}: CreatorHasCampaignActionButtonProps) {
  const { t } = useTranslation(['manager']);

  const navigate = useNavigate();

  const [
    instagramChannelInsightsDialogOpen,
    setInstagramChannelInsightsDialogOpen,
  ] = useState<boolean>(false);

  const [
    acceptCampaignApplicationDialogOpen,
    setAcceptCampaignApplicationDialogOpen,
  ] = useState<boolean>(false);

  const [retryPreviewDialogOpen, setRetryPreviewDialogOpen] =
    useState<boolean>(false);

  const [
    declineCampaignApplication,
    { loading: declineCampaignApplicationLoading },
  ] = useMutation(declineCampaignApplicationDocument);

  const [acceptPreview, { loading: acceptPreviewLoading }] = useMutation(
    acceptPreviewDocument,
  );

  const [downloadContentFile, { loading: downloadContentFileLoading }] =
    useDownloadContentFile();

  const canDownloadContentFile = useMemo(
    () =>
      creatorHasCampaign.campaign.includeContentFileOnSubmission &&
      (creatorHasCampaign.status ===
        CreatorHasCampaignStatus.CONTENT_SUBMITTED ||
        creatorHasCampaign.status === CreatorHasCampaignStatus.REPORT_FAILED ||
        creatorHasCampaign.status ===
          CreatorHasCampaignStatus.REPORT_COMPLETED),
    [
      creatorHasCampaign.campaign.includeContentFileOnSubmission,
      creatorHasCampaign.status,
    ],
  );

  const handleOnNavigateToMessages = useCallback(() => {
    navigate(`/messages/${creatorHasCampaign.id}`);
  }, [navigate, creatorHasCampaign.id]);

  const handleOnOpenInstagramChannelInsightsDialog = useCallback(() => {
    setInstagramChannelInsightsDialogOpen(true);
  }, []);

  const handleOnCloseInstagramChannelInsightsDialog = useCallback(() => {
    setInstagramChannelInsightsDialogOpen(false);
  }, []);

  const handleOnNavigateToReport = useCallback(() => {
    navigate(`/campaign-report/${creatorHasCampaign.campaign.id}`);
  }, [navigate, creatorHasCampaign.campaign.id]);

  const handleOnDeclineApplication = useCallback(async () => {
    await declineCampaignApplication({
      variables: {
        creatorHasCampaignId: creatorHasCampaign.id,
      },
    });
  }, [declineCampaignApplication, creatorHasCampaign.id]);

  const handleOnDownloadContentFile = useCallback(async () => {
    if (!canDownloadContentFile) {
      return;
    }
    await downloadContentFile(creatorHasCampaign.id);
  }, [canDownloadContentFile, creatorHasCampaign.id, downloadContentFile]);

  const handleOnOpenAcceptCampaignApplicationDialog = useCallback(() => {
    setAcceptCampaignApplicationDialogOpen(true);
  }, []);

  const handleOnCloseAcceptCampaignApplicationDialog = useCallback(() => {
    setAcceptCampaignApplicationDialogOpen(false);
  }, []);

  const handleOnAcceptPreview = useCallback(async () => {
    await acceptPreview({
      variables: {
        creatorHasCampaignId: creatorHasCampaign.id,
      },
    });
  }, [acceptPreview, creatorHasCampaign.id]);

  const handleOnOpenRetryPreviewDialog = useCallback(() => {
    setRetryPreviewDialogOpen(true);
  }, []);

  const handleOnCloseRetryPreviewDialog = useCallback(() => {
    setRetryPreviewDialogOpen(false);
  }, []);

  return (
    <>
      <ActionButton
        sx={{
          mt: 2,
        }}
        actions={[
          {
            primary:
              creatorHasCampaign.status === CreatorHasCampaignStatus.APPLIED,
            icon: faCheck,
            label: t('manager:creatorHasCampaign.acceptCampaignApplication'),
            hidden:
              creatorHasCampaign.status !== CreatorHasCampaignStatus.APPLIED,
            onClick: handleOnOpenAcceptCampaignApplicationDialog,
          },
          {
            icon: faXmark,
            label: t('manager:creatorHasCampaign.declineCampaignApplication'),
            hidden:
              creatorHasCampaign.status !== CreatorHasCampaignStatus.APPLIED,
            confirm: {
              title: t(
                'manager:creatorHasCampaign.confirmDeclineCampaignApplication.title',
              ),
              description: t(
                'manager:creatorHasCampaign.confirmDeclineCampaignApplication.description',
              ),
              buttonText: t(
                'manager:creatorHasCampaign.declineCampaignApplication',
              ),
              color: 'error',
            },
            disabled: declineCampaignApplicationLoading,
            onClick: handleOnDeclineApplication,
          },
          {
            primary:
              creatorHasCampaign.status ===
              CreatorHasCampaignStatus.PREVIEW_SUBMITTED,
            icon: faCheck,
            label: t('manager:creatorHasCampaign.acceptPreview'),
            hidden:
              creatorHasCampaign.status !==
              CreatorHasCampaignStatus.PREVIEW_SUBMITTED,
            confirm: {
              title: t('manager:creatorHasCampaign.confirmAcceptPreview.title'),
              description: t(
                'manager:creatorHasCampaign.confirmAcceptPreview.description',
              ),
            },
            disabled: acceptPreviewLoading,
            onClick: handleOnAcceptPreview,
          },
          {
            icon: faRepeat,
            label: t('manager:creatorHasCampaign.retryPreview'),
            hidden:
              creatorHasCampaign.status !==
              CreatorHasCampaignStatus.PREVIEW_SUBMITTED,
            onClick: handleOnOpenRetryPreviewDialog,
          },
          {
            primary:
              creatorHasCampaign.status ===
                CreatorHasCampaignStatus.CONTENT_SUBMITTED ||
              creatorHasCampaign.status ===
                CreatorHasCampaignStatus.REPORT_FAILED ||
              creatorHasCampaign.status ===
                CreatorHasCampaignStatus.REPORT_COMPLETED,
            icon: faChartMixed,
            label: t('manager:creatorHasCampaign.campaign.report'),
            onClick: handleOnNavigateToReport,
          },
          {
            primary:
              creatorHasCampaign.status !== CreatorHasCampaignStatus.APPLIED &&
              creatorHasCampaign.status !==
                CreatorHasCampaignStatus.PREVIEW_SUBMITTED &&
              creatorHasCampaign.status !==
                CreatorHasCampaignStatus.CONTENT_SUBMITTED &&
              creatorHasCampaign.status !==
                CreatorHasCampaignStatus.REPORT_FAILED &&
              creatorHasCampaign.status !==
                CreatorHasCampaignStatus.REPORT_COMPLETED,
            icon: faMessage,
            label: t('manager:creatorHasCampaign.messages'),
            onClick: handleOnNavigateToMessages,
          },
          {
            icon: faChartPie,
            label: t('manager:channelInsights.button'),
            hidden:
              creatorHasCampaign.channel?.channelable.__typename !==
              'InstagramChannel',
            onClick: handleOnOpenInstagramChannelInsightsDialog,
          },
          {
            icon: faFileArrowDown,
            label: t('manager:creatorHasCampaign.downloadContentFile'),
            hidden: !canDownloadContentFile,
            disabled: downloadContentFileLoading,
            onClick: handleOnDownloadContentFile,
          },
        ]}
        variant="contained"
        size="large"
      />
      {creatorHasCampaign.channel?.channelable.__typename ===
      'InstagramChannel' ? (
        <InstagramChannelInsightsDialog
          open={instagramChannelInsightsDialogOpen}
          data={creatorHasCampaign.channel.channelable}
          onClose={handleOnCloseInstagramChannelInsightsDialog}
        />
      ) : null}
      <AcceptCampaignApplicationDialog
        open={acceptCampaignApplicationDialogOpen}
        creatorHasCampaign={creatorHasCampaign}
        onClose={handleOnCloseAcceptCampaignApplicationDialog}
      />
      <RetryPreviewDialog
        open={retryPreviewDialogOpen}
        creatorHasCampaign={creatorHasCampaign}
        onClose={handleOnCloseRetryPreviewDialog}
      />
    </>
  );
}
