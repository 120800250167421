import { Link as RouterLink } from 'react-router';
import { Link, Stack, Typography } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { creatorHasCampaignMessageHeaderDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import ProfilePicture from '../../components/ProfilePicture.tsx';

interface MessagesHeaderProps {
  creatorHasCampaignId: string;
}

export default function MessagesHeader({
  creatorHasCampaignId,
}: MessagesHeaderProps) {
  const { data } = useSuspenseQuery(creatorHasCampaignMessageHeaderDocument, {
    variables: {
      id: creatorHasCampaignId,
    },
  });

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <RouterLink
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
        to={`/creator-has-campaign/${creatorHasCampaignId}`}>
        <ProfilePicture
          username={data.creatorHasCampaign.creator.username}
          src={data.creatorHasCampaign.creator.profilePicture}
          size={35}
        />
      </RouterLink>
      <Stack alignItems="flex-start" justifyContent="center">
        <Link
          position="relative"
          component={RouterLink}
          to={`/creator-has-campaign/${creatorHasCampaignId}`}
          variant="subtitle1"
          lineHeight={1.2}>
          {data.creatorHasCampaign.creator.username}
        </Link>
        <Typography variant="caption" color="text.secondary" lineHeight={1.2}>
          {data.creatorHasCampaign.campaign.name}
        </Typography>
      </Stack>
    </Stack>
  );
}
