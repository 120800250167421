import { RouteObject } from 'react-router';
import ChooseSubscription from '../pages/ChooseSubscription/ChooseSubscription.tsx';
import CreateFirstBrand from '../pages/CreateFirstBrand/CreateFirstBrand.tsx';
import SubscriptionPastDue from '../pages/SubscriptionPastDue/SubscriptionPastDue.tsx';
import SetupLayout from '../layouts/SetupLayout.tsx';
import RouteError from './RouteError.tsx';

const SetupRoutes: RouteObject = {
  element: <SetupLayout />,
  errorElement: <RouteError />,
  children: [
    {
      index: true,
      path: 'choose-subscription',
      element: <ChooseSubscription />,
    },
    {
      path: 'create-first-brand',
      element: <CreateFirstBrand />,
    },
    {
      path: 'subscription-past-due',
      element: <SubscriptionPastDue />,
    },
  ],
};

export default SetupRoutes;
