import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid2,
  Typography,
  Tooltip,
  CardProps,
} from '@mui/material';
import { formatEuroCents } from '@social-garden/utils/helpers.ts';
import { Download } from '@social-garden/utils/types.ts';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { ORANGE } from '@social-garden/utils/colors.ts';
import { Link } from 'react-router';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import ProfilePicture from './ProfilePicture.tsx';
import ChannelStatistics, {
  ChannelStatisticsData,
} from './ChannelStatistics.tsx';
import useCreatorHasCampaignStatus from '../hooks/useCreatorHasCampaignStatus.ts';
import ChannelMissing from './ChannelMissing.tsx';

export interface CreatorHasCampaignListItemData {
  id: string;
  creator: {
    username: string;
    isUnreliable: boolean;
    profilePicture?: Download | null;
  };
  channel?: {
    channelable: ChannelStatisticsData;
  } | null;
  status: CreatorHasCampaignStatus;
  price: number;
}

interface CreatorHasCampaignListItemProps extends Omit<CardProps, 'children'> {
  data: CreatorHasCampaignListItemData;
}

export default function CreatorHasCampaignListItem({
  data,
  ...rest
}: CreatorHasCampaignListItemProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['manager']);

  const { getCreatorHasCampaignStatusName, getCreatorHasCampaignStatusColor } =
    useCreatorHasCampaignStatus();

  return (
    <Card {...rest}>
      <CardActionArea component={Link} to={`/creator-has-campaign/${data.id}`}>
        <CardContent>
          <Grid2 container spacing={2} alignItems="center">
            <Grid2
              size={{
                xs: 12,
                sm: 'auto',
              }}>
              <ProfilePicture
                src={data.creator.profilePicture}
                username={data.creator.username}
                size={100}
              />
            </Grid2>
            <Grid2
              container
              size={{
                xs: 12,
                sm: 'grow',
              }}
              spacing={0.5}>
              <Grid2 container size={12}>
                <Grid2 size="auto">
                  <Typography
                    overflow="hidden"
                    textOverflow="ellipsis"
                    lineHeight="normal"
                    fontSize={22}>
                    {data.creator.username}
                  </Typography>
                </Grid2>
                {data.creator.isUnreliable ? (
                  <Grid2 size="auto">
                    <Tooltip title={t('manager:creator.isUnreliable')}>
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        color={ORANGE['500']}
                      />
                    </Tooltip>
                  </Grid2>
                ) : null}
              </Grid2>
              <Grid2 size={12}>
                {data.channel ? (
                  <ChannelStatistics data={data.channel.channelable} />
                ) : (
                  <ChannelMissing />
                )}
              </Grid2>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 'grow',
              }}
              display="flex"
              justifyContent={{
                xs: 'flex-start',
                sm: 'center',
              }}>
              <Chip
                label={getCreatorHasCampaignStatusName(data.status)}
                color={getCreatorHasCampaignStatusColor(data.status)}
                variant="filled"
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 'auto',
              }}>
              <Typography variant="h5">
                {formatEuroCents(data.price, resolvedLanguage)}
              </Typography>
            </Grid2>
          </Grid2>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
