import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  Chip,
  Grid2,
  Stack,
  Typography,
} from '@mui/material';
import {
  getContrastColor,
  getUriWithHash,
} from '@social-garden/utils/helpers.ts';
import { Download } from '@social-garden/utils/types.ts';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import {
  getChannelTypeColor,
  getChannelTypeName,
} from '@social-garden/utils/channel.ts';
import { getContentTypeNameFromCampaignType } from '@social-garden/utils/content.ts';
import { useMemo } from 'react';
import {
  CampaignStatus,
  CampaignTypeableFragment,
} from '@social-garden/api/gql/graphql.ts';
import Brand from './Brand.tsx';
import useCampaignStatus from '../hooks/useCampaignStatus.ts';

export interface CampaignListItemData {
  id: string;
  name: string;
  status: CampaignStatus;
  headerImage: Download;
  brand: {
    name: string;
    logo: Download;
  };
  typeable: CampaignTypeableFragment['typeable'];
  statistics?: {
    applicationsToReview: number;
    previewsToReview: number;
  } | null;
}

interface CampaignListItemProps extends Omit<CardProps, 'children'> {
  data: CampaignListItemData;
}

export default function CampaignListItem({
  data,
  ...rest
}: CampaignListItemProps) {
  const { t } = useTranslation(['manager']);

  const { getCampaignStatusName } = useCampaignStatus();

  const mediaFilter = useMemo(() => {
    if (
      data.statistics?.applicationsToReview ||
      data.statistics?.previewsToReview
    ) {
      return 'grayscale(85%) brightness(85%)';
    }
    return 'none';
  }, [
    data.statistics?.applicationsToReview,
    data.statistics?.previewsToReview,
  ]);

  return (
    <Card {...rest}>
      <CardActionArea
        sx={{
          position: 'relative',
        }}
        component={Link}
        to={`/campaign-details/${data.id}`}>
        <CardMedia
          sx={{
            aspectRatio: 21 / 9,
            width: '100%',
            filter: mediaFilter,
          }}
          image={getUriWithHash(data.headerImage)}
          title={data.name}
          draggable={false}
        />
        <Stack position="absolute" top={5} right={5} spacing={1}>
          {data.statistics?.applicationsToReview ? (
            <Chip
              label={t('campaign.statistics.applicationsToReview', {
                count: data.statistics.applicationsToReview,
              })}
              size="small"
              color="applied"
            />
          ) : null}
          {data.statistics?.previewsToReview ? (
            <Chip
              label={t('campaign.statistics.previewsToReview', {
                count: data.statistics.previewsToReview,
              })}
              size="small"
              color="previewSubmitted"
            />
          ) : null}
        </Stack>
        <CardContent>
          <Grid2 container spacing={1}>
            <Grid2 size={12}>
              <Typography
                variant="subtitle1"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                lineHeight={1.2}
                fontSize={20}>
                {data.name}
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Brand
                name={data.brand.name}
                logo={data.brand.logo}
                size="small"
              />
            </Grid2>
            <Grid2 container size={12}>
              <Grid2 size="auto">
                <Chip
                  label={getCampaignStatusName(data.status)}
                  variant="filled"
                  size="small"
                />
              </Grid2>
              <Grid2 size="auto">
                <Chip
                  sx={{
                    backgroundColor: getChannelTypeColor(
                      data.typeable.channelType,
                    ),
                    color: getContrastColor(
                      getChannelTypeColor(data.typeable.channelType),
                    ),
                  }}
                  label={getChannelTypeName(data.typeable.channelType)}
                  variant="filled"
                  size="small"
                />
              </Grid2>
              <Grid2 size="auto">
                <Chip
                  sx={{
                    backgroundColor: getChannelTypeColor(
                      data.typeable.channelType,
                    ),
                    color: getContrastColor(
                      getChannelTypeColor(data.typeable.channelType),
                    ),
                  }}
                  label={getContentTypeNameFromCampaignType(data.typeable)}
                  variant="filled"
                  size="small"
                />
              </Grid2>
            </Grid2>
          </Grid2>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
