import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useParams } from 'react-router';
import { useSuspenseQuery } from '@apollo/client';
import { campaignReportDocument } from '@social-garden/api/documents/campaign.ts';
import { Container, Stack } from '@mui/material';
import { useMemo } from 'react';
import InstagramReelCampaignReport from './InstagramReelCampaignReport.tsx';
import TiktokVideoCampaignReport from './TiktokVideoCampaignReport.tsx';
import YoutubeVideoCampaignReport from './YoutubeVideoCampaignReport.tsx';
import InstagramPostCampaignReport from './InstagramPostCampaignReport.tsx';
import InstagramStoryCampaignReport from './InstagramStoryCampaignReport.tsx';
import CampaignReportHeader from './CampaignReportHeader.tsx';

export default function CampaignReport() {
  const { id } = useParams();
  const { data } = useSuspenseQuery(campaignReportDocument, {
    variables: {
      id: id ?? '',
    },
  });

  const reportable = useMemo(() => {
    if (!data.campaign.reportable) {
      return null;
    }

    switch (data.campaign.reportable.__typename) {
      case 'InstagramReelCampaignReport':
        return <InstagramReelCampaignReport data={data.campaign.reportable} />;
      case 'InstagramPostCampaignReport':
        return <InstagramPostCampaignReport data={data.campaign.reportable} />;
      case 'InstagramStoryCampaignReport':
        return <InstagramStoryCampaignReport data={data.campaign.reportable} />;
      case 'TiktokVideoCampaignReport':
        return <TiktokVideoCampaignReport data={data.campaign.reportable} />;
      case 'YoutubeVideoCampaignReport':
        return <YoutubeVideoCampaignReport data={data.campaign.reportable} />;
      default:
        assertUnreachable(data.campaign.reportable);
    }
  }, [data.campaign.reportable]);

  return (
    <Container maxWidth="xl" component={Stack} spacing={4}>
      <CampaignReportHeader campaign={data.campaign} />
      {reportable}
    </Container>
  );
}
