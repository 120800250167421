import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { retryPreviewDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import {
  RetryPreviewFieldValues,
  RetryPreviewSchema,
} from '../../constants/ValidationSchema.ts';
import { RenderControllerElement } from '../../utils/types.ts';

interface RetryPreviewDialogProps {
  open: boolean;
  creatorHasCampaign: {
    id: string;
    creator: {
      username: string;
    };
  };
  onClose: () => void;
}

export default function RetryPreviewDialog({
  open,
  creatorHasCampaign,
  onClose,
}: RetryPreviewDialogProps) {
  const { t } = useTranslation(['common', 'manager']);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<RetryPreviewFieldValues>({
    mode: 'all',
    resolver: zodResolver(RetryPreviewSchema),
    defaultValues: {
      reason: '',
    },
  });

  const [retryPreview, { loading: retryPreviewLoading }] =
    useMutation(retryPreviewDocument);

  const handleOnRetryPreview = useCallback(
    async ({ reason }: RetryPreviewFieldValues) => {
      const response = await retryPreview({
        variables: {
          input: {
            creatorHasCampaignId: creatorHasCampaign.id,
            reason,
          },
        },
      });
      if (response.data?.retryPreview) {
        onClose();
      }
    },
    [creatorHasCampaign.id, retryPreview, onClose],
  );

  const renderReason = useCallback<
    RenderControllerElement<RetryPreviewFieldValues, 'reason'>
  >(
    ({
      field: { name, value, onBlur, onChange },
      fieldState: { error, invalid },
    }) => (
      <TextField
        required
        fullWidth
        variant="outlined"
        multiline
        minRows={3}
        label={t('common:previewRevision.retriedReason')}
        name={name}
        defaultValue={value}
        error={invalid}
        helperText={error?.message}
        onBlur={onBlur}
        onChange={onChange}
      />
    ),
    [t],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      PaperProps={{
        component: 'form',
        noValidate: true,
        onSubmit: handleSubmit(handleOnRetryPreview),
      }}
      onClose={onClose}>
      <DialogTitle>
        {t('manager:retryPreview.dialog.title', {
          username: creatorHasCampaign.creator.username,
        })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText>
            {t('manager:retryPreview.dialog.description')}
          </DialogContentText>
          <Controller<RetryPreviewFieldValues, 'reason'>
            render={renderReason}
            name="reason"
            control={control}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button type="reset" color="inherit" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="warning"
          disabled={!isValid || retryPreviewLoading}>
          {t('manager:retryPreview.dialog.submitButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
