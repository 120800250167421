import { Avatar, AvatarProps } from '@mui/material';
import { getUriWithHash } from '@social-garden/utils/helpers.ts';
import { Download } from '@social-garden/utils/types.ts';

interface BrandLogoProps extends Omit<AvatarProps, 'src' | 'variant'> {
  src: Download;
}

export default function BrandLogo({
  sx,
  src,
  draggable = false,
  slotProps,
  ...rest
}: BrandLogoProps) {
  return (
    <Avatar
      sx={{
        ...sx,
        aspectRatio: 1,
        background: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
      }}
      src={getUriWithHash(src)}
      draggable={draggable}
      variant="circular"
      slotProps={{
        ...slotProps,
        img: {
          draggable: draggable,
          ...slotProps?.img,
        },
      }}
      {...rest}
    />
  );
}
