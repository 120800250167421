import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router';
import { Button, Typography, Stack, Link as MuiLink } from '@mui/material';
import {
  EmailLoginFieldValues,
  EmailLoginSchema,
} from '../../constants/ValidationSchema.ts';
import EmailField from '../../components/EmailField.tsx';
import PasswordField from '../../components/PasswordField.tsx';
import useLoginManager from '../../hooks/useLoginManager.ts';

export default function Login() {
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<EmailLoginFieldValues>({
    resolver: zodResolver(EmailLoginSchema),
    mode: 'all',
  });
  const { t } = useTranslation(['manager']);
  const [loginManager, { loading }] = useLoginManager();

  const handleOnValid = useCallback(
    async ({ email, password }: EmailLoginFieldValues) => {
      await loginManager({
        email,
        password,
      });
    },
    [loginManager],
  );

  return (
    <Stack spacing={4}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h5" align="center">
          {t('login.header')}
        </Typography>
      </Stack>
      <Stack
        component="form"
        spacing={2}
        noValidate
        onSubmit={handleSubmit(handleOnValid)}>
        <Controller<EmailLoginFieldValues, 'email'>
          name="email"
          control={control}
          render={EmailField}
        />
        <Controller<EmailLoginFieldValues, 'password'>
          name="password"
          control={control}
          render={PasswordField}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || loading}>
          {t('login.submit')}
        </Button>
        <MuiLink component={Link} to="/forgot-password">
          {t('login.forgotPasswordLink')}
        </MuiLink>
        <MuiLink component={Link} to="/register">
          {t('login.registerLink')}
        </MuiLink>
      </Stack>
    </Stack>
  );
}
