import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation(['manager']);
  const navigate = useNavigate();

  const handleOnGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Stack
      flex={1}
      spacing={3}
      p={3}
      alignItems="center"
      justifyContent="center"
      textAlign="center">
      <Typography variant="h2" align="center">
        {t('notFound.header')}
      </Typography>
      <Typography variant="h4" align="center">
        {t('notFound.description')}
      </Typography>
      <Button variant="text" onClick={handleOnGoBack}>
        {t('notFound.backButton')}
      </Button>
    </Stack>
  );
}
