import { useLazyQuery } from '@apollo/client';
import { creatorHasCampaignContentFileDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useCallback } from 'react';

export default function useDownloadContentFile() {
  const [creatorHasCampaignContentFile, { loading, error }] = useLazyQuery(
    creatorHasCampaignContentFileDocument,
  );

  const handleOnDownloadContentFile = useCallback(
    async (creatorHasCampaignId: string) => {
      const response = await creatorHasCampaignContentFile({
        variables: {
          id: creatorHasCampaignId,
        },
      });
      if (response.data) {
        window.open(response.data.creatorHasCampaignContentFile.uri, '_blank');
      }
    },
    [creatorHasCampaignContentFile],
  );

  return [handleOnDownloadContentFile, { loading, error }] as const;
}
