import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid2,
} from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { campaignWithStatisticsDocument } from '@social-garden/api/documents/campaign.ts';
import {
  getChannelTypeColor,
  getChannelTypeName,
} from '@social-garden/utils/channel.ts';
import {
  getContrastColor,
  getUriWithHash,
} from '@social-garden/utils/helpers.ts';
import { getContentTypeNameFromCampaignType } from '@social-garden/utils/content.ts';
import CreatorsOfCampaign from './CreatorsOfCampaign.tsx';
import Brand from '../../components/Brand.tsx';
import CampaignStatistics from '../../components/CampaignStatistics.tsx';
import useCampaignStatus from '../../hooks/useCampaignStatus.ts';
import PageTitle from '../../components/PageTitle.tsx';
import CampaignDetailsActionButton from './CampaignDetailsActionButton.tsx';

export default function CampaignDetails() {
  const { id } = useParams();

  const { data } = useSuspenseQuery(campaignWithStatisticsDocument, {
    variables: {
      id: id ?? '',
    },
  });

  const { getCampaignStatusName } = useCampaignStatus();

  return (
    <Container>
      <Grid2 container spacing={4}>
        <Grid2 size={12}>
          <Card variant="outlined">
            <CardMedia
              sx={{
                aspectRatio: 21 / 9,
                width: '100%',
                maxHeight: 250,
              }}
              image={getUriWithHash(data.campaign.headerImage)}
              title={data.campaign.name}
              draggable={false}
            />
            <CardContent>
              <Grid2 container spacing={2}>
                <Grid2 container alignItems="center" size={12}>
                  <Grid2
                    size={{
                      xs: 12,
                      md: 'grow',
                    }}>
                    <PageTitle>{data.campaign.name}</PageTitle>
                  </Grid2>
                  <Grid2
                    size={{
                      xs: 12,
                      md: 'auto',
                    }}>
                    <CampaignDetailsActionButton campaign={data.campaign} />
                  </Grid2>
                </Grid2>
                <Grid2 size={12}>
                  <Brand
                    name={data.campaign.brand.name}
                    logo={data.campaign.brand.logo}
                  />
                </Grid2>
                <Grid2 container size={12} spacing={1}>
                  <Grid2 size="auto">
                    <Chip
                      label={getCampaignStatusName(data.campaign.status)}
                      variant="filled"
                      size="small"
                    />
                  </Grid2>
                  <Grid2 size="auto">
                    <Chip
                      label={data.campaign.category.name}
                      variant="filled"
                      size="small"
                    />
                  </Grid2>
                  <Grid2 size="auto">
                    <Chip
                      sx={{
                        backgroundColor: getChannelTypeColor(
                          data.campaign.typeable.channelType,
                        ),
                        color: getContrastColor(
                          getChannelTypeColor(
                            data.campaign.typeable.channelType,
                          ),
                        ),
                      }}
                      label={getChannelTypeName(
                        data.campaign.typeable.channelType,
                      )}
                      variant="filled"
                      size="small"
                    />
                  </Grid2>
                  <Grid2 size="auto">
                    <Chip
                      sx={{
                        backgroundColor: getChannelTypeColor(
                          data.campaign.typeable.channelType,
                        ),
                        color: getContrastColor(
                          getChannelTypeColor(
                            data.campaign.typeable.channelType,
                          ),
                        ),
                      }}
                      label={getContentTypeNameFromCampaignType(
                        data.campaign.typeable,
                      )}
                      variant="filled"
                      size="small"
                    />
                  </Grid2>
                </Grid2>
                {data.campaign.statistics ? (
                  <Grid2 size={12}>
                    <CampaignStatistics statistics={data.campaign.statistics} />
                  </Grid2>
                ) : null}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={12}>
          <CreatorsOfCampaign campaignId={data.campaign.id} />
        </Grid2>
      </Grid2>
    </Container>
  );
}
