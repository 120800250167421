import { Navigate, Outlet } from 'react-router';
import useManagerLayoutOutletContext from '../hooks/useManagerLayoutOutletContext.ts';

export default function AppLayout() {
  const { manager } = useManagerLayoutOutletContext();

  if (manager.company.currentSubscription?.status === 'past_due') {
    return <Navigate to="/subscription-past-due" />;
  }

  if (
    !manager.company.currentSubscription ||
    !manager.company.currentSubscription.subscriptionPlan ||
    manager.company.currentSubscription.status !== 'active'
  ) {
    return <Navigate to="/choose-subscription" />;
  }
  if (manager.company.brands.length === 0) {
    return <Navigate to="/create-first-brand" />;
  }

  return <Outlet />;
}
