import { RouteObject } from 'react-router';
import ManagerLayout from '../layouts/ManagerLayout.tsx';
import { managerLoader } from './loader.ts';
import AppRoutes from './AppRoutes.tsx';
import SetupRoutes from './SetupRoutes.tsx';
import Logout from '../pages/Logout/Logout.tsx';
import CustomerPortal from '../pages/CustomerPortal/CustomerPortal.tsx';
import Admin from '../pages/Admin/Admin.tsx';
import AccountSettings from '../pages/AccountSettings/AccountSettings.tsx';
import VerifyUpdatedEmail from '../pages/VerifyUpdatedEmail/VerifyUpdatedEmail.tsx';
import RouteError from './RouteError.tsx';

const ManagerRoutes: RouteObject = {
  loader: managerLoader,
  element: <ManagerLayout />,
  errorElement: <RouteError />,
  children: [
    SetupRoutes,
    AppRoutes,
    {
      path: 'customer-portal',
      element: <CustomerPortal />,
    },
    {
      path: 'account-settings',
      element: <AccountSettings />,
    },
    {
      path: 'verify-updated-email',
      element: <VerifyUpdatedEmail />,
    },
    {
      path: 'admin',
      element: <Admin />,
    },
    {
      path: 'logout',
      element: <Logout />,
    },
  ],
};

export default ManagerRoutes;
