import { ChannelType } from './types';
import { assertUnreachable } from './helpers';

interface InstagramOEmbedUrlParameters {
  channelType: ChannelType.INSTAGRAM | 'InstagramChannel';
  url: string;
  accessToken: string;
  maxWidth?: number;
  maxHeight?: number;
}

interface TiktokOEmbedUrlParameters {
  channelType: ChannelType.TIKTOK | 'TiktokChannel';
  url: string;
  maxWidth?: number;
  maxHeight?: number;
}

interface YoutubeOEmbedUrlParameters {
  channelType: ChannelType.YOUTUBE | 'YoutubeChannel';
  url: string;
  maxWidth?: number;
  maxHeight?: number;
}

type ChannelTypeOEmbedUrlParameters =
  | InstagramOEmbedUrlParameters
  | TiktokOEmbedUrlParameters
  | YoutubeOEmbedUrlParameters;

interface BasicOEmbedResponse {
  version: '1.0';
  title?: string;
}

export interface PhotoOEmbedResponse extends BasicOEmbedResponse {
  type: 'photo';
  url: string;
  width: number;
  height: number;
}

export interface VideoOEmbedResponse extends BasicOEmbedResponse {
  type: 'video';
  html: string;
  width: number;
  height: number;
}

export interface RichOEmbedResponse extends BasicOEmbedResponse {
  type: 'rich';
  html: string;
  width: number;
  height?: number;
}

export type OEmbedResponse =
  | PhotoOEmbedResponse
  | VideoOEmbedResponse
  | RichOEmbedResponse;

export function getChannelTypeOEmbedUrl(
  params: ChannelTypeOEmbedUrlParameters,
) {
  let optionalParam = '';
  if (params.maxWidth) {
    optionalParam = optionalParam + '&maxwidth=' + params.maxWidth;
  }
  if (params.maxHeight) {
    optionalParam = optionalParam + '&maxheight=' + params.maxHeight;
  }

  switch (params.channelType) {
    case ChannelType.YOUTUBE:
    case 'YoutubeChannel':
      return `https://www.youtube.com/oembed?url=${params.url}&format=json${optionalParam}`;
    case ChannelType.INSTAGRAM:
    case 'InstagramChannel':
      return `https://graph.facebook.com/v19.0/instagram_oembed?url=${params.url}&access_token=${params.accessToken}&format=json${optionalParam}`;
    case ChannelType.TIKTOK:
    case 'TiktokChannel':
      return `https://www.tiktok.com/oembed?url=${params.url}&format=json${optionalParam}`;
    default:
      assertUnreachable(params);
  }
}
