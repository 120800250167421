import {
  Container,
  FormControl,
  Grid2,
  InputLabel,
  Link,
  SelectChangeEvent,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  assertUnreachable,
  formatEuroCents,
} from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { campaignReportsDocument } from '@social-garden/api/documents/campaign.ts';
import { Link as RouterLink } from 'react-router';
import PageTitle from '../../components/PageTitle.tsx';
import BrandSelect from '../../components/BrandSelect.tsx';
import useInfiniteScrollPagination from '../../hooks/useInfiniteScrollPagination.ts';
import useBrandIds from '../../hooks/useBrandIds.ts';

function getFollowerOrSubscriberCount(
  reportable:
    | { __typename: 'InstagramReelCampaignReport'; followerCount: number }
    | { __typename: 'InstagramPostCampaignReport'; followerCount: number }
    | { __typename: 'InstagramStoryCampaignReport'; followerCount: number }
    | { __typename: 'TiktokVideoCampaignReport'; followerCount: number }
    | { __typename: 'YoutubeVideoCampaignReport'; subscriberCount: number },
) {
  switch (reportable.__typename) {
    case 'InstagramReelCampaignReport':
    case 'InstagramPostCampaignReport':
    case 'InstagramStoryCampaignReport': {
      return reportable.followerCount;
    }
    case 'TiktokVideoCampaignReport':
      return reportable.followerCount;
    case 'YoutubeVideoCampaignReport':
      return reportable.subscriberCount;
    default:
      assertUnreachable(reportable);
  }
}

export default function CampaignReports() {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common', 'manager']);
  const [brandIds, setBrandIds] = useBrandIds();

  const { data, loading, fetchMore } = useQuery(campaignReportsDocument, {
    variables: {
      brandIds,
      first: 25,
      page: 1,
    },
  });

  useInfiniteScrollPagination({
    paginator: data?.campaigns ?? {
      data: [],
      paginatorInfo: {
        currentPage: 1,
        hasMorePages: false,
      },
    },
    fetchMore,
  });

  const handleOnBrandChange = useCallback(
    async (event: SelectChangeEvent<string[]>) => {
      const selected = event.target.value;
      if (Array.isArray(selected)) {
        await setBrandIds(selected);
      }
    },
    [setBrandIds],
  );

  return (
    <Container>
      <Grid2 container spacing={4}>
        <Grid2 size={12}>
          <PageTitle>{t('manager:reports.title')}</PageTitle>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 8,
            lg: 5,
            xl: 4,
          }}>
          <FormControl fullWidth>
            <InputLabel id="filter-brand-label">
              {t('manager:campaign.filter.brand.label')}
            </InputLabel>
            <BrandSelect
              variant="outlined"
              labelId="filter-brand-label"
              label={t('manager:campaign.filter.brand.label')}
              value={brandIds}
              multiple
              onChange={handleOnBrandChange}
            />
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <DataGrid
            loading={loading || data === undefined}
            rows={data?.campaigns.data ?? []}
            columns={[
              {
                flex: 1,
                minWidth: 250,
                field: 'name',
                headerName: t('manager:reports.campaignName'),
                valueGetter: (_, row) => row.name,
                renderCell: ({ row }) => (
                  <Link
                    component={RouterLink}
                    to={`/campaign-report/${row.id}`}>
                    {row.name}
                  </Link>
                ),
              },
              {
                width: 150,
                field: 'completedCount',
                headerName: t('common:report.completed'),
                valueGetter: (_, row) =>
                  row.reportable
                    ? row.reportable.completedCount.toLocaleString(
                        resolvedLanguage,
                      )
                    : 'n/a',
              },
              {
                width: 120,
                field: 'followerCount',
                headerName: t('common:report.followers'),
                valueGetter: (_, row) =>
                  row.reportable
                    ? getFollowerOrSubscriberCount(
                        row.reportable,
                      ).toLocaleString(resolvedLanguage)
                    : 'n/a',
              },
              {
                width: 100,
                field: 'cpv',
                headerName: t('common:report.cpv'),
                description: t('common:report.cpvDescription'),
                valueGetter: (_, row) =>
                  row.reportable?.cpv
                    ? formatEuroCents(row.reportable.cpv, resolvedLanguage)
                    : 'n/a',
              },
              {
                width: 120,
                field: 'price',
                headerName: t('common:report.price'),
                valueGetter: (_, row) =>
                  row.reportable?.price
                    ? formatEuroCents(row.reportable.price, resolvedLanguage)
                    : 'n/a',
              },
            ]}
            slots={{
              pagination: null,
            }}
          />
        </Grid2>
      </Grid2>
    </Container>
  );
}
